<template>
  <v-app>
    <HeaderView />
    <v-main>
      <BodyView>
        <router-view />
      </BodyView>
    </v-main>
    <FooterView />
  </v-app>
</template>

<script>
import HeaderView from './components/HeaderView.vue'
import FooterView from './components/FooterView.vue'
import BodyView from './components/BodyView.vue'

export default {
  name: 'App',
  components: {
    HeaderView,
    FooterView,
    BodyView
  }
}
</script>