<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1 class="text-h4 text-sm-h5 mb-4">평택시 아파트 매매정보 조회</h1>
            </v-col>
        </v-row>
        
        <!-- 검색 필터 영역 -->
        <v-row>
            <v-col cols="12">
                <div class="query-params-container">
                    <v-text-field
                        v-model="fromDate"
                        label="조회 시작일"
                        type="date"
                        :max="toDate"
                        density="compact"
                        class="query-field"
                    ></v-text-field>
                    <v-text-field
                        v-model="toDate"
                        label="조회 종료일"
                        type="date"
                        :min="fromDate"
                        density="compact"
                        class="query-field"
                    ></v-text-field>
                    <v-btn
                        color="primary"
                        @click="validateDates"
                        :disabled="!fromDate || !toDate"
                        class="search-btn"
                        :block="$vuetify.display.smAndDown"
                    >
                        조회하기
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- 데이터 테이블 영역 -->
        <v-row v-if="trades && trades.length > 0">
            <v-col cols="12">
                <div class="table-container">
                    <v-data-table
                        :headers="headers"
                        :items="trades"
                        v-model:options="options"
                        :server-items-length="totalItems"
                        :loading="isLoading"
                        @update:options="handleTableUpdate"
                        :footer-props="{
                            'items-per-page-options': [10],
                            'show-first-last-page': true,
                            'show-current-page': true,
                        }"
                        density="compact"
                        class="elevation-1"
                    >
                        <!-- 기존 템플릿 슬롯들 유지 -->
                        <template #[`item.dealDate`]="{ item }">
                            {{ formatDate(item) }}
                        </template>
                        <template #[`item.fullAddress`]="{ item }">
                            {{ item.umdNm }} {{ item.jibun }}
                        </template>
                        <template #[`item.dealAmount`]="{ item }">
                            {{ formatAmount(item.dealAmount) }}
                        </template>
                        <template #[`item.excluUseAr`]="{ item }">
                            {{ item.excluUseAr }}
                        </template>
                        <template #[`item.buildYear`]="{ item }">
                            {{ item.buildYear }}
                        </template>
                        <template #[`item.floor`]="{ item }">
                            {{ item.floor }}
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <p class="text-center">조회된 데이터가 없습니다.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            trades: [],
            fromDate: '',
            toDate: '',
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false,
            },
            totalItems: 0,
            isLoading: false,
            headers: [
                { 
                    title: '거래일',
                    key: 'dealDate',
                    align: 'start',
                    sortable: true,
                    width: '100px'
                },
                { 
                    title: '아파트명',
                    key: 'aptNm',
                    align: 'start',
                    width: '150px'
                },
                { 
                    title: '주소',
                    key: 'fullAddress',
                    align: 'start',
                    width: '200px'
                },
                { 
                    title: '거래금액',
                    key: 'dealAmount',
                    align: 'end',
                    width: '100px'
                },
                { 
                    title: '층',
                    key: 'floor',
                    align: 'end',
                    width: '50px'
                },
                { 
                    title: '면적(㎡)',
                    key: 'excluUseAr',
                    align: 'end',
                    width: '80px'
                },
                { 
                    title: '건축년도',
                    key: 'buildYear',
                    align: 'end',
                    width: '80px'
                },
            ]
        }
    },
    methods: {
        validateDates() {
            if (this.fromDate && this.toDate) {
                if (this.fromDate > this.toDate) {
                    this.$toast.error('시작일은 종료일보다 이전이어야 합니다.');
                    return false;
                }
            }
            this.options.page = 1;
            this.fetchData();
        },
        formatDate(item) {
            return `${item.dealYear}-${String(item.dealMonth).padStart(2, '0')}-${String(item.dealDay).padStart(2, '0')}`;
        },
        
        formatAmount(amount) {
            if (!amount) return '';
            return amount.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        async fetchData() {
            if (!this.fromDate || !this.toDate) {
                return;
            }

            this.isLoading = true;
            try {
                const params = new URLSearchParams({
                    page: this.options.page,
                    size: this.options.itemsPerPage,
                    fromDate: this.fromDate,
                    toDate: this.toDate
                });
                // 정렬 처리 추가
                if (this.options.sortBy.length > 0) {
                    params.append('sort', `${this.options.sortBy[0]},${this.options.sortDesc[0] ? 'desc' : 'asc'}`);
                }
                
                const response = await fetch(`https://api.banseok-real-estate.co.kr/realty/apt/trade?${params}`);
                const data = await response.json();
                
                console.log('Fetched data:', data);

                if (data && Array.isArray(data.items)) {
                    this.trades = data.items;
                    this.totalItems = parseInt(data.total) || 0;
                } else {
                    this.trades = [];
                    this.totalItems = 0;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                alert('데이터 조회 중 오류가 발생했습니다.');
                this.trades = [];
                this.totalItems = 0;
            } finally {
                this.isLoading = false;
            }
        },
        handleTableUpdate(newOptions) {
            this.options = newOptions;
            this.fetchData();
        }
    },
    watch: {
        options: {
            handler() {
                this.fetchData();
            },
            deep: true
        }
    },
    created() {
        this.trades = [];
    },
}
</script>

<style>
.query-params-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 20px;
}

.query-field {
    flex: 1;
    min-width: 200px;
}

.search-btn {
    align-self: flex-start;
    margin-top: 4px;
}

/* 모바일 화면을 위한 미디어 쿼리 */
@media (max-width: 600px) {
    .query-params-container {
        flex-direction: column;
    }

    .query-field {
        width: 100%;
        min-width: unset;
    }

    .search-btn {
        width: 100%;
        margin-top: 8px;
    }
}

/* 테이블 반응형 스타일 */
.table-container {
    width: 100%;
    overflow-x: auto;
}

/* 모바일에서 테이블 폰트 크기 조정 */
@media (max-width: 600px) {
    .v-data-table {
        font-size: 0.875rem;
    }
}
</style>